import React from 'react';
import { Modal, Button } from '@isyc-react/design/bootstrap';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
export const ModalInfo = props => {
    const { text, type = '', children } = props;
    const { t } = useTranslation(namespaces.common);
    const closeModal = () => {
        props.onHide();
        if (props.refresh)
            props.refresh();
        if (props.handleCancelDrop)
            props.handleCancelDrop();
    };
    return (React.createElement(Modal, Object.assign({}, props, { "aria-labelledby": 'contained-modal-title-vcenter', centered: true }),
        React.createElement(Modal.Header, { className: 'border-bottom-0 bg-white p-4' },
            React.createElement(Button, { type: 'button', className: 'btn-close me-1', "aria-label": t('ls.modal.confirm.button.close'), onClick: closeModal })),
        React.createElement(Modal.Body, null,
            React.createElement("span", { className: 'modal-info-body text-primary ' + type }, text)),
        React.createElement(Modal.Footer, { className: 'modal-footer d-flex justify-content-center border-0 px-5 pb-5' }, children)));
};
